var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('div',[_c('CContainer',{staticClass:"min-vh-100 d-flex align-items-center mob-res-pd0 mob-ai-flex-start mob-bg-white"},[_c('CRow',{staticClass:"w-100 justify-content-center form-wrapper-mf mob-m0 login-form"},[_c('CCol',{staticClass:"form-left",attrs:{"md":"4"}},[_c('div',{staticClass:"form-right-title"},[_vm._v(" "+_vm._s(_vm.$t("reserPassword.title"))+" ")]),_c('div',{staticClass:"form-sub-message"},[_vm._v(_vm._s(_vm.$t("reserPassword.desc")))])]),_c('CCol',{staticClass:"mob-res-form-bg",attrs:{"md":"8"}},[_c('div',{staticClass:"logo-wrapper align-center"},[_c('span',{staticClass:"brand-logo"})]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"form-wrapper"},[_c('div',{staticClass:"form-title"},[_vm._v(" "+_vm._s(_vm.$t("reserPassword.title"))+" ")]),_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitPassword)}}},[_c('div',[(_vm.isValidToken)?_c('div',{staticClass:"form-field"},[_c('cTextInputComponent',{attrs:{"isAutoComplete":false,"label":_vm.$t('reserPassword.newPwd'),"type":'password',"rules":"required|agt_pwd","placeholder":_vm.$t('reserPassword.newPwdPl')},model:{value:(_vm.newPwd),callback:function ($$v) {_vm.newPwd=$$v},expression:"newPwd"}})],1):_vm._e(),(_vm.isValidToken)?_c('div',{staticClass:"form-field p-relative"},[_c('cTextInputComponent',{attrs:{"isAutoComplete":false,"label":_vm.$t('reserPassword.confrmPwd'),"type":'text',"rules":"required|agt_pwd","placeholder":_vm.$t('reserPassword.confrmPwdPl')},model:{value:(_vm.confrmPwd),callback:function ($$v) {_vm.confrmPwd=$$v},expression:"confrmPwd"}})],1):_vm._e(),(_vm.alertValue)?_c('CAlert',{attrs:{"show":"","color":"danger"}},[(
                          _vm.alertValue && _vm.alertValue.hasOwnProperty('valueList')
                        )?_c('div',_vm._l((_vm.alertValue.valueList),function(value,index){return _c('p',{key:index},[_vm._v(" "+_vm._s(value)+" ")])}),0):_vm._e(),(
                          _vm.alertValue && _vm.alertValue.hasOwnProperty('codeList')
                        )?_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t("cAlert.errorCode") + " : "))]),_vm._l((_vm.alertValue.codeList),function(code,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(code + " ")+" ")])})],2):_vm._e(),(_vm.alertValue && _vm.alertValue.constructor == String)?_c('div',[_c('p',[_vm._v(" "+_vm._s(_vm.alertValue)+" ")])]):_vm._e()]):_vm._e(),(_vm.isValidToken)?_c('div',{staticClass:"form-field align-right mt-25px"},[_c('CButton',{staticClass:"btn-primary-mf",attrs:{"color":"success","type":"submit"}},[_vm._v(_vm._s(_vm.$t("reserPassword.submit")))])],1):_vm._e()],1)])])]}}])}),_c('div')],1)],1)],1)],1),(_vm.isShowAlertModal)?_c('cAlertModal',{attrs:{"buttonList":[
        { name: _vm.$t('reserPassword.ok'), func: _vm.navToLogin, color: 'success' } ],"status":1,"isShowIcon":true,"show":_vm.isShowAlertModal,"desc":_vm.$t('reserPassword.resetSuccess')}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }