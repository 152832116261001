<template>
  <div>
    <div>
      <div>
        <CContainer
          class="min-vh-100 d-flex align-items-center mob-res-pd0 mob-ai-flex-start mob-bg-white"
        >
          <CRow
            class="w-100 justify-content-center form-wrapper-mf mob-m0 login-form"
          >
            <CCol md="4" class="form-left">
              <div class="form-right-title">
                {{ $t("reserPassword.title") }}
              </div>
              <div class="form-sub-message">{{ $t("reserPassword.desc") }}</div>
            </CCol>

            <CCol md="8" class="mob-res-form-bg">
              <div class="logo-wrapper align-center">
                <span class="brand-logo"> </span>
              </div>

              <ValidationObserver v-slot="{ handleSubmit }">
                <div class="form-wrapper">
                  <div class="form-title">
                    {{ $t("reserPassword.title") }}
                  </div>
                  <form @submit.prevent="handleSubmit(submitPassword)">
                    <div>
                      <!-- Login part -->
                      <!-- <div v-if="showLogin"> -->
                      <div class="form-field" v-if="isValidToken">
                        <cTextInputComponent
                          v-model="newPwd"
                          :isAutoComplete="false"
                          :label="$t('reserPassword.newPwd')"
                          :type="'password'"
                          rules="required|agt_pwd"
                          :placeholder="$t('reserPassword.newPwdPl')"
                        ></cTextInputComponent>
                      </div>
                      <!-- rules="noSpace|required|min:8|letter|number|oneSplChar|Pswdmax:20" -->
                      <div class="form-field p-relative" v-if="isValidToken">
                        <cTextInputComponent
                          v-model="confrmPwd"
                          :isAutoComplete="false"
                          :label="$t('reserPassword.confrmPwd')"
                          :type="'text'"
                          rules="required|agt_pwd"
                          :placeholder="$t('reserPassword.confrmPwdPl')"
                        ></cTextInputComponent>
                      </div>

                      <CAlert v-if="alertValue" show color="danger">
                        <div
                          v-if="
                            alertValue && alertValue.hasOwnProperty('valueList')
                          "
                        >
                          <p
                            v-for="(value, index) in alertValue.valueList"
                            :key="index"
                          >
                            {{ value }}
                          </p>
                        </div>
                        <div
                          v-if="
                            alertValue && alertValue.hasOwnProperty('codeList')
                          "
                        >
                          <span>{{ $t("cAlert.errorCode") + " : " }}</span
                          ><span
                            v-for="(code, index) in alertValue.codeList"
                            :key="index"
                          >
                            {{ code + " " }}
                          </span>
                        </div>
                        <div
                          v-if="alertValue && alertValue.constructor == String"
                        >
                          <p>
                            {{ alertValue }}
                          </p>
                        </div>
                      </CAlert>
                      <!-- </div> -->

                      <div
                        class="form-field align-right mt-25px"
                        v-if="isValidToken"
                      >
                        <CButton
                          color="success"
                          type="submit"
                          class="btn-primary-mf"
                          >{{ $t("reserPassword.submit") }}</CButton
                        >
                      </div>
                    </div>
                  </form>
                </div>
              </ValidationObserver>
              <div></div>
            </CCol>
          </CRow>
        </CContainer>
      </div>

      <cAlertModal
        v-if="isShowAlertModal"
        :buttonList="[
          { name: $t('reserPassword.ok'), func: navToLogin, color: 'success' },
        ]"
        :status="1"
        :isShowIcon="true"
        :show="isShowAlertModal"
        :desc="$t('reserPassword.resetSuccess')"
      ></cAlertModal>
    </div>
  </div>
</template>

<script>
import { addClassToBody, removeClassBody } from "../../util/util";
import { PATHNAME, COMPONENT_NAME, LOGIN_PAGE } from "../../util/constants";
import cTextInputComponent from "../../components/form/cTextInputComponent";
import { ValidationObserver } from "vee-validate";
import BaseConfiguration from "../../config/base";
import {
  VALIDATE_SUCCESS_RES,
  getErrorMessage,
} from "../../util/apiValidate.js";
import { LOG_TYPE, FILE_NAME } from "../../config/API_DATA";
import Logger from "../../services/logger";
import { handleJWT, getErrorAlertValue } from "../../util/util";
import router, { navigate } from "../../router/index";
import apiResetPassword from "../../services/apiResetPassword";
import { mapActions } from "vuex";
import cAlertModal from "../../components/form/cAlertModal";
import apiValidateToken from "../../services/apiValidateToken";

export default {
  name: COMPONENT_NAME.RESET_PASSWORD,
  components: {
    cTextInputComponent,
    ValidationObserver,
    cAlertModal,
  },

  data() {
    return {
      newPwd: null,
      confrmPwd: null,
      alertValue: null,
      isShowAlertModal: false,
      login: null,
      isValidToken: true,
    };
  },

  methods: {
    ...mapActions("sSysLogin", ["GET_ADMIN_SIGNIN_RES"]),
    ...mapActions("sSysLogin", ["SET_ADMIN_SIGNIN_RES"]),
    showAlert(val) {
      this.alertValue = val;
    },
    async submitPassword() {
      try {
        if (BaseConfiguration.isDebug) console.log("submitPassword");
        if (this.newPwd == this.confrmPwd) {
          this.showAlert(null);
          let loginTo = await this.resetPassword(this.newPwd);
          if (loginTo && loginTo.login) {
            this.showAlert(null);
            this.login = loginTo.login;
            this.isShowAlertModal = true;
          }
        } else {
          this.showAlert(this.$t("reserPassword.misMatch"));
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.RESET_PASSWORD,
          "error in submitPassword",
          err.toString(),
          "submitPassword",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

    async resetPassword(pNewPwd) {
      try {
        if (BaseConfiguration.isDebug) console.log("resetPassword");
        let retValue;
        if (pNewPwd) {
          let pswdReq = apiResetPassword.buildRequest(pNewPwd);

          if (pswdReq) {
            let pswdRes = await apiResetPassword.getResponse(pswdReq);
            if (VALIDATE_SUCCESS_RES(pswdRes)) {
              retValue = pswdRes.data.data;
            } else {
              retValue = false;
              let error = getErrorMessage(pswdRes);
              let isJwt = handleJWT(error);
              if (!isJwt) {
                this.showAlert(getErrorAlertValue(error));
              }
            }
          } else {
            throw "invalid request";
          }
        }
        return retValue;
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.RESET_PASSWORD,
          "error in resetPassword",
          err.toString(),
          "resetPassword",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

    navToLogin() {
      try {
        if (BaseConfiguration.isDebug) console.log("navToLogin");
        let login = this.login;
        if (login == LOGIN_PAGE.SYSTEM) navigate(PATHNAME.SYSTEM_LOGIN);
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.RESET_PASSWORD,
          "error in navToLogin",
          err.toString(),
          "navToLogin",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      //return retValue;
    },

    async validateToken(pToken) {
      try {
        if (BaseConfiguration.isDebug) console.log("validateToken");
        let retValue;
        if (pToken) {
          let validateTokenReq = apiValidateToken.buildRequest(pToken);

          if (validateTokenReq) {
            let validateTokenRes = await apiValidateToken.getResponse(
              validateTokenReq
            );
            if (VALIDATE_SUCCESS_RES(validateTokenRes)) {
              retValue = validateTokenRes.data.data;
            } else {
              let error = getErrorMessage(validateTokenRes);
              let isJwt = handleJWT(error);
              if (!isJwt) {
                this.showAlert(getErrorAlertValue(error));
              }
            }
          } else {
            throw "invalid request";
          }
        }
        return retValue;
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.RESET_PASSWORD,
          "error in validateToken",
          err.toString(),
          "validateToken",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },
  },
  async created() {
    try {
      if (BaseConfiguration.isDebug) console.log("created");
      let signinData = {
        authorization: null,
      };
      if (router.currentRoute.query && router.currentRoute.query.token) {
        signinData.authorization = router.currentRoute.query.token;
        let validateTokenRes = await this.validateToken(
          router.currentRoute.query.token
        );
        if (validateTokenRes && validateTokenRes.isValid) {
          this.isValidToken = validateTokenRes.isValid;
        } else {
          this.isValidToken = false;
        }
      }
      if (signinData.authorization) {
        this.SET_ADMIN_SIGNIN_RES(signinData);
      }
    } catch (err) {
      let body = Logger.buildRequest(
        FILE_NAME.RESET_PASSWORD,
        "error in created",
        err.toString(),
        "created",
        LOG_TYPE.ERROR
      );
      Logger.getResponse(body);
    }
  },
  beforeCreate() {
    addClassToBody(COMPONENT_NAME.RESET_PASSWORD);
  },
  beforeDestroy() {
    removeClassBody();
  },
};
</script>